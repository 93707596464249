
import axios from 'axios';
export default {
    
        async createItemImages(params)  {
            return await axios.post(`item_images/create` , params)
        },
        async createItemImagesList(params)  {
            return await axios.post(`item_images/create/list` , params)
        },
        async updateItemImagesColumn(column , value , data)  {
            return await axios.put(`item_images/update_list?${column}=${value}` , data)
        },
        async deleteItemImagesList(list)  {
            return await axios.delete(`item_images/delete_list` , {
                data: {
                    list:list
                }
            })
        },
        async reportItemImages(column , value)  {
            return await axios.get(`item_images/report?${column}=${value}`)
        },
        async getAllItemImages()  {
            return await axios.get(`item_images/all`)
        },
        async getOneItemImages(item_image_id)  {
            return await axios.get(`item_images/all/${item_image_id}`)
        },
        async getItemImagesByColumn(column , value)  {
            return await axios.get(`item_images/filter?column=${column}&value=${value}`)
        },
        async deleteItemImages(item_image_id)  {
            return await axios.delete(`item_images/delete/${item_image_id}`)
        },
        async updateItemImages(item_image_id , params)  {
            return await axios.put(`item_images/update/${item_image_id}` , params)
        },
}
        